import type * as Apollo from '@apollo/client'
import type { Field } from '#field/types'
import {
  SETTINGS_PACKS_QUERY,
  SETTINGS_PACK_QUERY,
  ADD_UPDATE_SETTINGS_PACK_MUTATION,
  DELETE_SETTINGS_PACK_MUTATION,
  TOGGLE_ASSIGN_SETTINGS_PACK_MUTATION,
  SETTINGS_PACK_CUSTOM_FIELDS_QUERY,
} from '#settings-pack/schema'
import type {
  SettingsPack,
  CreateSettingsPackParams,
  UpdateSettingsPackParams,
  ToggleAssignSettingsPackParams,
} from '#settings-pack/types'

export const useListSettingsPacksQuery = (
  workspaceId: string,
  options: Apollo.QueryHookOptions = {}
) => {
  return useQuery<{
    settingsPacks: SettingsPack[]
  }>(
    SETTINGS_PACKS_QUERY,
    {
      workspaceId,
    },
    options
  )
}

export const useSettingsPackLazyQuery = (
  id: string,
  options: Apollo.QueryHookOptions = {},
) => {
  return useLazyQuery<{
    settingsPack: SettingsPack
  }>(
    SETTINGS_PACK_QUERY,
    {
      id,
    },
    options,
  )
}

export const useLazySettingPackCustomFieldsQuery = (
  id: string,
) => {
  return useLazyQuery<{
    settingsPackFields: Field[]
  }>(
    SETTINGS_PACK_CUSTOM_FIELDS_QUERY,
    {
      id,
    },
  )
}

export const useAddUpdateSettingsPackMutation = (
  options: Apollo.MutationHookOptions<{
    addUpdateSettingsPack: { settingsPack: SettingsPack }
  }>
) => {
  return useMutation(ADD_UPDATE_SETTINGS_PACK_MUTATION, {
    ...defaultMutationOptions,
    ...options,
  })
}

export const useCreateSettingsPackMutation = (params: CreateSettingsPackParams) => {
  return useMutation(ADD_UPDATE_SETTINGS_PACK_MUTATION, {
    ...defaultMutationOptions,
    variables: { input: params },
    refetchQueries: [
      {
        query: SETTINGS_PACKS_QUERY,
        variables: {
          workspaceId: params.workspace,
        },
      },
    ],
  })
}

export const useUpdateSettingsPackMutation = (
  params: UpdateSettingsPackParams
) => {
  return useMutation(ADD_UPDATE_SETTINGS_PACK_MUTATION, {
    ...defaultMutationOptions,
    variables: { input: params },
  })
}

export const useDeleteSettingsPackMutation = (id: string) => {
  return useMutation(DELETE_SETTINGS_PACK_MUTATION, {
    ...defaultMutationOptions,
    variables: {
      id,
    },
  })
}

export const useToggleAssignSettingsPackMutation = (
  // params: ToggleAssignSettingsPackParams
  options: Apollo.MutationHookOptions<{
    toggleAssignSettingsPack: { settingsPack: SettingsPack }
  }>
) => {
  return useMutation(TOGGLE_ASSIGN_SETTINGS_PACK_MUTATION, {
    ...defaultMutationOptions,
    ...options
  })
}
